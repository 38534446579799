<template src="./RecoveryAtIdSuccess.html">
</template>

<script>
import ArrobaIdIcon from '@/components/Icons/ArrobaIdIcon';
export default {
  name: 'recovery-at-id-success',
  components: { ArrobaIdIcon },
  methods: {
    redirectTo() {
      this.$router.push({path: '/login'})
    }
  }
}
</script>

<style lang="scss" scoped src="./RecoveryAtIdSuccess.scss">
</style>